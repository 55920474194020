<template>
  <section class="card">
    <link rel="stylesheet" type="text/css" href="/assets/css/relatorios.css">

    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header">
        <b>Relatório de Encerramentos</b>
      </template>
      <template slot="subheader">
        <br />
        <small>Para extrair o relatório, clique em filtrar ao lado</small>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
      </filtro-component>
      <div :class="'h-100 '+(filtros.visible ? 'col-lg-10 p0' : 'col-lg-12')">
        <div :class="'card-body card-tabela ' + (filtros.visible ? '' : 'p0')">
          <div class="h-100">
            <div class="table-responsive records">

              <table class="table table-bordered table-striped table-hover mb-0 table-sm" id="report_table">
                <thead>
                  <tr>

                    <th>
                      <span>
                        Est
                      </span>

                    </th>
                    <th @click="handleOrdenar('Id')">
                      <span>
                        Despesa
                      </span>
                      <span class="pull-right">
                        <i v-if="params.order == 'Id'"
                           :class="'el ' + (params.direction == 'desc' ? 'el-chevron-down' : 'el-chevron-up')">

                        </i>
                      </span>
                    </th>
                    <th>
                      <span>
                        Nota/Série
                      </span>
                    </th>
                    <th>
                      <span>
                        Classif.
                      </span>
                    </th>
                    <th>
                      <span>
                        Fornecedor
                      </span>
                    </th>
                    <th>
                      <span>
                        Descrição
                      </span>
                    </th>
                    <th>
                      <span>
                        Aprovador
                      </span>
                    </th>
                    <th>
                      <span>
                        Tipo
                      </span>
                    </th>
                    <th>
                      <span>
                        Valor
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item) in grouped">
                    <tr :key="item[0].Placa">
                      <td colspan="9">
                        <b style="width:13%">Placa: {{item[0].Placa}}</b>
                        <b style="width:28%">{{item[0].Veiculo}}</b>
                        <b style="width:15%">Vlr Orçado: {{item[0].VlrOrcado ? item[0].VlrOrcado.toFixed(2).replace('.','.') : ''}}</b>
                        <b style="width:14%">Ent: {{item[0].DEntradaDisplay}}</b>
                        <b style="width:12%">D.Est: {{item[0].ES}}</b>
                        <b style="width:14%">Venda: {{item[0].DVendaDisplay}}</b>
                      </td>
                    </tr>
                    <tr v-for="(nf) in item" :key="(nf.RequisicaoId)">
                      <td>{{nf.Estoque}}</td>
                      <td>{{nf.Id}}</td>
                      <td>{{nf.NotaSerie}}</td>
                      <td>{{nf.Classificacao}}</td>
                      <td><span class="wrap">{{nf.Fornecedor}}</span></td>
                      <td><span class="wrap">{{nf.Servico}}</span></td>
                      <td><span class="wrap">{{nf.UsuarioAprovador}}</span></td>
                      <td>{{nf.TipoRequisicao}}</td>
                      <td>{{nf.ValorNF.toFixed(2).replace('.',',')}}</td>
                    </tr>
                    <tr :key="item[0].Id">
                      <td colspan="9" class="total">
                        <b>{{ item.map(c => c.ValorNF).reduce((partial_sum, a) => partial_sum + a).toFixed(2).replace('.',',') }}</b>
                      </td>
                    </tr>
                  </template>
                  <tr v-if="result && result.Records && result.Records.length">
                    <td colspan="4"><b>{{result.Records.length}} resultados</b></td>
                    <td colspan="5" class="total"><b>{{ (result.Records.length ? result.Records.map(c => c.ValorNF).reduce((partial_sum, a) => partial_sum + a) : 0).toFixed(2).replace('.',',') }}</b></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination :pagination="result.Pagination"
                        :params="params"
                        @trocar-pagina="(page) => { handleConsultar(page) }">

            </pagination>
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
  import api from '@/services/api.js';
  import consultar from '@/services/consultar.js';
  import { resolveStatusRequisicaoClass, groupBy } from '@/services/helpers.js';


  //Relatorios Encerramento
  export default {

    components: {
      ...consultar.components,
    },
    data() {
      const that = this;
      return {
        ...consultar.data(),
        url: '/relatorios/encerramento',
        clone: '',
        obj: {},
        tipoRequisicaoFiltro: [],
        actionButtons: [
          {
            text: '',
            className: 'print padrao',
            icon: 'fas fa-print',
            //iconColor: 'green',
            color: 'default',
            visible: true,
            title: 'Imprimir relatório',
            callback: that.imprimir
          },
          {
            text: '',
            className: 'excel padrao',
            icon: 'fas fa-file-excel',
            iconColor: 'green',
            color: 'default',
            visible: true,
            title: 'Exportar para excel',
            callback: that.$root.exportCurrentTable

          },
        ],
        itemSelecionado: [0],
        filtros:
        {
          data: [
            {
              id: 'StatusVeiculo',
              title: 'Status Veículo',
              active: false,
              value: [],
              source: [
                //{
                //    id: 'StatusVeiculo',
                //    title: 'Pendente',
                //    value: 0
                //},
                {
                  id: 'StatusVeiculo',
                  title: 'Preparo',
                  value: 1
                },
                {
                  id: 'StatusVeiculo',
                  title: 'Estoque',
                  value: 2
                },
                {
                  id: 'StatusVeiculo',
                  title: 'Vendido',
                  value: 3
                },
              ]
            },
            {
              id: 'StatusRequisicao',
              title: 'Status Requisição',
              active: false,
              source: [{
                id: 'StatusRequisicao',
                title: 'Orçamento',
                value: 0
              },
              {
                id: 'StatusRequisicao',
                title: 'Pendente',
                value: 1
              },
              {
                id: 'StatusRequisicao',
                title: 'Reprovado',
                value: 2
              },
              {
                id: 'StatusRequisicao',
                title: 'Aprovado',
                value: 3
              },
              {
                id: 'StatusRequisicao',
                title: 'Enviado',
                value: 4
              },
              {
                id: 'StatusRequisicao',
                title: 'Executado',
                value: 5
              },
              {
                id: 'StatusRequisicao',
                title: 'Encerrado',
                value: 6
              }
              ],
              value: []

            },
            {
              id: 'TipoServico',
              title: 'Tipo Serviço',
              active: false,
              value: [],
              source: []
            },
            {
              id: 'DataCadastro',
              title: 'Data Cadastro',
              active: false,
              type: 'date'
            },
            {
              id: 'DataSituacao',
              title: 'Data Situação',
              active: false,
              type: 'date'
            },
            {
              id: 'tipoNota',
              title: 'Tipo Nota',
              active: false,
              value: [],
              sourceType: 'radio',
              source: [
                //{
                //    id: 'StatusVeiculo',
                //    title: 'Pendente',
                //    value: 0
                //},
                {
                  id: 'tipoNota',
                  title: 'NF',
                  value: 'NF'
                },
                {
                  id: 'tipoNota',
                  title: 'OS',
                  value: 'OS'

                },
              ]
            },
          ],
          visao: [
            {
              id: 0,
              title: 'Todas requisições',
              filtro: [
                {
                  id: 'DataSituacao',
                  valuede: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
                  valueate: ''
                }
              ]
            },
          ],
          visaoSelecionada: 0,
          visible: true
        },
        grouped: [],

      };
    },
    methods: {
      ...consultar.methods,
      changeCheckboxCallback: function () { this.itemSelecionado = [0] },
      callback: function (request) {
        this.itemSelecionado = [0];
        this.grouped = groupBy(request.data.Records, 'EstoqueId');
      },
      resolveStatusClass: resolveStatusRequisicaoClass,
      somarNotas: function (results) {
        var total = 0;
        for (var i in results) {
          for (var j in results[i].Estoque.Requisicao) {
            for (var h in results[i].Estoque.Requisicao[j].NotaFiscal) {
              total += results[i].Estoque.Requisicao[j].NotaFiscal[h].Valor;
            }
          }
        }

        return total.toFixed(2).replace('.', ',');
      }
    },
    mounted: function () {
      this.handleTrocarVisao(true);
    },
    beforeMount: function () {
      let that = this;
      let filtro = this.filtros.data.filter(f => f.id === 'TipoServico')[0];
      api.get(this.url + '?preload=true')
        .then(function (response) {
          filtro.source = response.data.tipoRequisicaoFiltro;
        });
    }
  };
</script>

<style scoped>


  td b {
    display: inline-block;
    text-decoration: underline
  }

  .total {
    text-align: right
  }
</style>
